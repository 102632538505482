// import styles
import '../scss/style.scss';
import '../scss/admin.scss';
import '../scss/login.scss';

// import libraries, remove if unused
//import Swiper from 'Swiper';
// to include Swiper modules
import Swiper, {Navigation} from 'Swiper';

// import {
//   animateOnScroll,
//   advancedSticky,
//   parralax,
//   svgPathFill
// } from './libs/antimation'; // import animation functions, don't forget to import styles when using animateOnScroll

/***
 * Get Viewport Dimensions
 ***/
//var viewport;
var vpWidth;

// get vp dimensions
function updateViewportDimensions() {
    let x = window.innerWidth || document.documentElement.clientWidth;
    let y = window.innerHeight || document.documentElement.clientWidth;

    //viewport = {width: x, height: y};
    vpWidth = x;
}

updateViewportDimensions();

// menu toggle
function clickMenuItem(e) {
    e.stopPropagation();
    e.preventDefault();
    e.target.parentNode.classList.add('active');
}

function adjustMenu() {
    const toggleMenu = document.querySelector('.toggleMenu');
    const topNav = document.querySelector('.main-menu');

    if (vpWidth < 1024) {
        if (toggleMenu.classList.contains('active')) {
            topNav.classList.add('active');
            fixbody();
        }

        // add click menu item
        const menuChild = topNav.querySelector('.menu-item-has-children > a');
        if (menuChild) {
            menuChild.removeEventListener('click', clickMenuItem);
            menuChild.addEventListener('click', clickMenuItem);
        }
    } else if (vpWidth >= 1024) {
        topNav.querySelector('li').classList.remove('active');
        topNav.querySelector('li a').removeEventListener('click', clickMenuItem);

        unfixBody();
    }
}

//on window resize event
window.addEventListener(
    'resize',
    throttle(() => {
        updateViewportDimensions();
        adjustMenu();
    }, 75)
);

const bodyElement = document.querySelector('body');
const navElement = document.querySelector('nav');
let newsletterNav = document.getElementById('newsletterNav');

// fix body(when mobile menu is active)
function fixbody() {
    let scrollPosition = window.pageYOffset;

    bodyElement.classList.add('menu-active');
    bodyElement.style.top = '-' + scrollPosition + 'px';
    bodyElement.setAttribute('data-scroll', scrollPosition);
}

// unfix body (when mobile menu untoggled)
function unfixBody() {
    if (bodyElement.classList.contains('menu-active')) {
        const scrollPosition = bodyElement.getAttribute('data-scroll');

        bodyElement.classList.remove('menu-active');
        bodyElement.style.top = 'auto';

        window.scrollTo(0, parseInt(scrollPosition));
    }
}

//remove hamburger menu mobile
newsletterNav.onclick = function () {
    bodyElement.classList.remove('menu-active');
    navElement.classList.remove('active');
}

// Swiper example, docs: https://swiperjs.com/swiper-api
const opinieSlider = document.querySelector('.swiper'); //klasse container
if (opinieSlider) {
    const swiperOpinie = new Swiper('.swiper', {
        // use modules in swiper instance
        modules: [Navigation],

        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 0,

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
}

/***
 *  Page load scripts
 ***/
document.addEventListener('DOMContentLoaded', (event) => {
    document.querySelector('body').classList.add('js-active');

    adjustMenu();

    // Mobile menu toggle
    document.querySelector('.toggleMenu').addEventListener('click', (e) => {
        e.preventDefault();
        const menu = document.querySelector('.main-menu');

        if (menu.classList.contains('active')) {
            menu.classList.remove('active');
            unfixBody();
        } else {
            menu.classList.add('active');
            fixbody();
        }
    });

    const selectors = [
        "iframe[src*='player.vimeo.com']",
        "iframe[src*='vimeo.com']",
        "iframe[src*='youtube.com']",
        "iframe[src*='youtube-nocookie.com']",
        "iframe[src*='kickstarter.com'][src*='video.html']",
        'object',
        'embed',
    ];
    reframe(selectors.join(','));

    // object fit polyfill for IE
    if (!Modernizr.objectfit) {
        document.querySelectorAll('.img-container').forEach((el) => {
            const imgUrl = el.querySelector('img').src;
            if (imgUrl) {
                el.style.backgroundImage = 'url(' + imgUrl + ')';
                el.classList.add('compat-object-fit');
            }
        });
    }

    jQuery('.more-pod-btn').on('click', function (e) {
        e.preventDefault();

        let offset = jQuery('.pod-list-items-container > a').length;
        let data = 'action=fcmc_more_podcasts&offset=' + offset + '&n=' + safe.ajax_nonce;

        jQuery.ajax({
            url: safe.ajax_url,
            type: 'GET',
            data: data,

            beforeSend: function (xhr) {
                jQuery('.pod-list-items-container').addClass('loading');
            },
            success: function (data) {

                let container = jQuery('.pod-list-items-container');

                container.append(data);
                let hide = jQuery('.related-last');
                console.log(hide.length);

                if (hide.length > 0) {
                    jQuery('.more-pod-btn').addClass('hidden');
                }

                container.removeClass('loading');

            }
        });
    });

    jQuery('.more-articles-btn').on('click', function (e) {
        e.preventDefault();

        let offset = jQuery('.opinie-container > div').length;
        let data = 'action=fcmc_more_articles&offset=' + offset + '&n=' + safe.ajax_nonce;


        jQuery.ajax({
            url: safe.ajax_url,
            type: 'GET',
            data: data,

            beforeSend: function (xhr) {
                jQuery('.opinie-container').addClass('loading');
            },
            success: function (data) {

                let container = jQuery('.opinie-container');

                container.append(data);
                let hide = jQuery('.related-last');
                console.log(hide.length);

                if (hide.length > 0) {
                    jQuery('.more-articles-btn').addClass('hidden');
                }


                container.removeClass('loading');

            }
        });
    });

});

/***
 * Intersection observers example
 ***/
// convert this into a class for easier use in this and other projects?
const options = {
    root: null, // viewport
    threshold: 0,
    rootMargin: '0px',
};

const animationTriggerObserver = new IntersectionObserver(
    (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('fcmc-animation');

                observer.unobserve(entry.target);
            }
        });
    },
    options
);

if (document.querySelectorAll('.green-underline-container').length > 0) {
    const underlines = document.querySelectorAll('.green-underline-container');

    underlines.forEach((el) => {
        animationTriggerObserver.observe(el);
    });
}

// helper function to throttle events
function throttle(callback, delay) {
    let previousCall = new Date().getTime();
    return function () {
        let time = new Date().getTime();

        if (time - previousCall >= delay) {
            previousCall = time;
            callback.apply(null, arguments);
        }
    };
}

// TODO move this to its own file and import it?
/***
 * start Reframe.js
 ***/
function reframe(target, cName) {
    var frames =
        typeof target === 'string' ? document.querySelectorAll(target) : target;
    var c = cName || 'js-reframe';
    if (!('length' in frames)) frames = [frames];
    for (var i = 0; i < frames.length; i += 1) {
        var frame = frames[i];
        var hasClass = frame.className.split(' ').indexOf(c) !== -1;
        if (hasClass || frame.style.width.indexOf('%') > -1) continue;
        var h = frame.getAttribute('height') || frame.offsetHeight;
        var w = frame.getAttribute('width') || frame.offsetWidth;
        var padding = (h / w) * 100;
        var div = document.createElement('div');
        div.className = c;
        var divStyles = div.style;
        divStyles.position = 'relative';
        divStyles.width = '100%';
        divStyles.paddingTop = padding + '%';
        var frameStyle = frame.style;
        frameStyle.position = 'absolute';
        frameStyle.width = '100%';
        frameStyle.height = '100%';
        frameStyle.left = '0';
        frameStyle.top = '0';
        frame.parentNode.insertBefore(div, frame);
        frame.parentNode.removeChild(frame);
        div.appendChild(frame);
    }
} // this gets called once dom is loaded
/* end Reframe.js */


/*********************
 * MINIPLAYER
 *********************/

/* Add and remove minplayer on scroll */
document.addEventListener('scroll', (e) => {

    let audioPlayer = document.querySelector('.audio-player');
    let player = document.querySelector('.sp-subscribe-section');
    let jsPlayer = document.querySelector('.js-player');
    let mpActive = document.querySelector('.sp-info-container');

    if (vpWidth >= 768) {
        if (player) {
            let playerTop = player.getBoundingClientRect().top;

            if (playerTop < 150) {

                if(!jsPlayer.classList.contains('miniplayer')){
                    jsPlayer.classList.add('miniplayer');

                   if(wavesurfer !== undefined) {
                       wavesurfer.drawBuffer();
                   }
                }

            } else {
                if(jsPlayer.classList.contains('miniplayer')){
                    jsPlayer.classList.remove('miniplayer');

                    if(wavesurfer !== undefined) {
                        wavesurfer.drawBuffer();
                    }
                }
            }
        }
    } else {
        if (player) {
            let playerTop = player.getBoundingClientRect().top;

            if (playerTop < 80) {
                audioPlayer.classList.add('miniplayer');

                if(!mpActive.classList.contains('mp-active')){
                    mpActive.classList.add('mp-active');

                    if(wavesurfer !== undefined) {
                        setTimeout(function(){ wavesurfer.drawBuffer(); }, 300);
                    }
                }

            } else {
                audioPlayer.classList.remove('miniplayer');

                if(mpActive.classList.contains('mp-active')){
                    mpActive.classList.remove('mp-active');

                    if(wavesurfer !== undefined) {
                        wavesurfer.drawBuffer();
                    }
                }
            }
        }
    }
})

/* Remove minplayer on window resize */
window.addEventListener('resize', (e) => {
    let audioPlayer = document.querySelector('.audio-player');
    let jsPlayer = document.querySelector('.js-player');
    let mpActive = document.querySelector('.sp-info-container');
    let miniplayerActive = document.querySelector('.miniplayer');

    if (vpWidth >= 768) {
        if (miniplayerActive) {
            audioPlayer.classList.remove('miniplayer');
            mpActive.classList.remove('mp-active');
        }
    } else {
        if (miniplayerActive) {
            jsPlayer.classList.remove('miniplayer');
        }
    }
})


/*********************
 * LOCAL SCROLL
 *********************/

const links = document.querySelectorAll('a[href*="#"]:not([href="#"]):not([href="#0"])');

links.forEach((element) => {
    const hrefUrl = new URL(element.href);

    if (window.location.hostname === hrefUrl.hostname && window.location.pathname === hrefUrl.pathname) {
        element.addEventListener("click", (e) => {
            e.preventDefault();
            const hashLink = hrefUrl.hash;
            const linkTarget = document.querySelector(hashLink);
            const targetTop = linkTarget.offsetTop - 100;

            localScroll(document.documentElement, targetTop, 750);
        });
    }
});

function localScroll(element, to, duration) {
    if (duration <= 0) return;

    let b = element.scrollTop;
    let c = to - element.scrollTop;

    animateLoop(element, 0, b, c, duration);
}

function animateLoop(element, currentTick, b, c, d) {
    setTimeout(function () {
        if (d === currentTick * 10) return;

        let t = currentTick * 10;

        currentTick++;

        element.scrollTop = Math.floor(easeInOutQuad(t, b, c, d));

        animateLoop(element, currentTick, b, c, d);
    }, 10);
}

function easeInOutQuad(t, b, c, d) {

    // https://spicyyoghurt.com/tools/easing-functions
    // t = Current time
    // b = Begin value
    // c = Change in value
    // d = Duration

    if ((t /= d / 2) < 1) return c / 2 * t * t + b;
    return -c / 2 * ((--t) * (t - 2) - 1) + b;
}

/* MENU DISSAPEAR ON SCROLL */
let lastDownPos = window.scrollY;
let lastPos = 0;
let directionSwap = 0;

document.addEventListener('scroll', (e) => {
    let currentPos = window.scrollY;
    let header = document.querySelector('.header');
    let HeaderHeight = header.clientHeight;
    let jsPlayer = document.querySelector('.js-player');

    if (vpWidth >= 768) {
        if (jsPlayer !== null) {
            jsPlayer.style.top = 0;
        }

        if ((currentPos > lastDownPos || currentPos > lastPos) && currentPos > 60) {
            lastPos = currentPos;
            lastDownPos = currentPos;
            header.style.top = "-" + HeaderHeight + "px ";

            if (jsPlayer !== null) {
                jsPlayer.style.top = 0;
            }

        } else if (currentPos < lastDownPos) {
            directionSwap = lastDownPos;
            lastPos = currentPos;
            if ((directionSwap - currentPos) > 50) {
                header.style.top = 0;

                if (jsPlayer !== null) {
                    jsPlayer.style.top = HeaderHeight + "px";
                }
            }
        }

    }

});
